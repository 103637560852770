import { trimBasenameAndNetworkFromUrlPath } from './trimBasenameFromUrlPath';
import { trimLeadingAndTrailingSlash } from './trimSlash';
import type { UrlRootPath } from './urlRootPath.types';
import { networkSchemaRootPaths, urlRootPaths } from './urlRootPath.types';

const getRootPath = (urlPath: string) => {
  const urlPathWithoutBasename = trimBasenameAndNetworkFromUrlPath(urlPath);
  const urlPathParts = trimLeadingAndTrailingSlash(urlPathWithoutBasename).split('/');

  return urlPathParts[0];
};

export type GetUrlRootPath = (urlPath: string) => string;
export const getUrlRootPath: GetUrlRootPath = (urlPath) => {
  const rootPath = getRootPath(urlPath);

  const hasEmptyPath = rootPath === '';

  const hasNetworkSchemaRootPath = networkSchemaRootPaths.includes(rootPath as UrlRootPath);
  const hasKnownRootPath = urlRootPaths.includes(rootPath as UrlRootPath);

  if (hasKnownRootPath && !hasNetworkSchemaRootPath) {
    return rootPath;
  }

  if (hasEmptyPath || hasNetworkSchemaRootPath) {
    return '';
  }

  return 'other';
};
