import { TelemetryEvent, isAnalyticsEvent, isAnalyticsV2Event } from '@ms/yammer-telemetry';
import { getCurrentPageName, getPageLoadNumber } from '@ms/yammer-telemetry-support';

const hasValue = <T>(value: T): value is NonNullable<T> => value != null;

const addVisibilityStateToLogEvent = async (event: TelemetryEvent): Promise<TelemetryEvent> =>
  isAnalyticsEvent(event) || isAnalyticsV2Event(event)
    ? event
    : {
        ...event,
        properties: {
          ...event.properties,
          visibilityState: document.visibilityState,
        },
      };

const addPageLoadFieldsToLogEventIfNotAlreadyPresent = async (event: TelemetryEvent): Promise<TelemetryEvent> => {
  if (isAnalyticsV2Event(event)) {
    return event;
  }

  return {
    ...event,
    properties: {
      ...event.properties,
      page: hasValue(event.properties?.page) ? event.properties.page : getCurrentPageName(),
      pageLoadNumber: hasValue(event.properties?.pageLoadNumber)
        ? event.properties.pageLoadNumber
        : getPageLoadNumber(),
    },
  };
};

const addUserAgentToAnalyticsV2Event = async (event: TelemetryEvent): Promise<TelemetryEvent> =>
  isAnalyticsV2Event(event)
    ? {
        ...event,
        properties: {
          ...event.properties,
          userAgent: navigator.userAgent,
        },
      }
    : event;

export type TelemetryMiddleware = (event: TelemetryEvent) => Promise<TelemetryEvent>;
const middlewares: TelemetryMiddleware[] = [
  addVisibilityStateToLogEvent,
  addUserAgentToAnalyticsV2Event,
  addPageLoadFieldsToLogEventIfNotAlreadyPresent,
];

export const applyMiddleware: TelemetryMiddleware = (event) =>
  middlewares.reduce(async (memoEvent, middleware) => middleware(await memoEvent), Promise.resolve(event));

export type AddMiddleware = (middleware: TelemetryMiddleware) => void;
export const addMiddleware: AddMiddleware = (middleware) => {
  middlewares.push(middleware);
};
