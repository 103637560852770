import { Entity, EntityEdge, EntityMap } from '@ms/yammer-data-support';
import { SearchResultType } from '@ms/yammer-search-types';

import { Campaign } from '../campaigns/types';
import { Group } from '../groups/types';
import { Network, Thread, User } from '../types';

export interface SearchParameters {
  readonly searchText: string;
  readonly resultType: SearchResultType;
  readonly groupScope?: Group['id'];
  readonly campaignId?: Campaign['id'];
  readonly startDate?: number;
  readonly endDate?: number;
  readonly networkId?: Network['id'];
  readonly participantUserIds?: User['id'][];
  readonly threadStarterSenderId?: User['id'];
  readonly postType?: string;
  readonly locale: string;
  readonly count?: number;
  readonly includeVerifiedReply?: boolean;
  readonly scopeType?: string;
}

export interface SearchLoadMoreParameters extends SearchParameters {
  readonly afterCursor?: string;
}

export type SearchResultEdge = EntityEdge<Entity> & { readonly cursor: string };

export interface SearchResultsForType extends Entity {
  readonly id: string;
  readonly results: SearchResultEdge[];
  readonly hasNextPage: boolean;
  readonly endCursor?: string;
}

export interface NetworkQuestionSearchResult extends Entity {
  readonly id: string;
  readonly results: SearchResultEdge[];
  readonly hasNextPage: boolean;
  readonly endCursor?: string;
}

export interface NetworkQuestionPublisherSearchResult extends Entity {
  readonly id: string;
  readonly results: SearchResultEdge[];
}

export type Searches = EntityMap<SearchResultsForType>;

export class SearchResultThreadRenderError extends Error {
  public threadId: Thread['id'];

  constructor(error: Error, threadId: Thread['id']) {
    super();

    this.threadId = threadId;
    this.message = error.message;
    this.stack = error.stack;

    Object.setPrototypeOf(this, SearchResultThreadRenderError.prototype);
  }
}
