import { error as logErrorToConsole } from '@ms/yammer-console-logging';
import { LogEvent, LogEvents, TelemetryEvent, TelemetryEventType } from '@ms/yammer-telemetry';
import { enqueueTelemetryEvents, getTelemetryClientConfig } from '@ms/yammer-telemetry-store';

import { applyMiddleware } from '../api/middleware';

import { getThrottledReportLogEventsInBatch } from './reportInBatches';
import { getReportLogEventsInput } from './reportLogEventsInput';
import { reportLogEventsNow } from './reportTelemetryEventsToServer';

const eventsAllowedForReportNow = ['AcquireTokenAuthError'];

type EnqueueAndThrottleReportLogEvents = <T extends TelemetryEvent>(
  eventType: TelemetryEventType,
  events: T[],
  /**
   * Will skip applying middleware to events if true. Use this option if your events have already been processed by middleware.
   */
  disableMiddleware?: boolean
) => Promise<void>;
export const enqueueAndThrottleReportLogEvents: EnqueueAndThrottleReportLogEvents = async <T extends TelemetryEvent>(
  eventType: TelemetryEventType,
  events: T[],
  disableMiddleware = false
) => {
  const processedEvents = disableMiddleware ? events : await Promise.all(events.map(applyMiddleware));
  enqueueTelemetryEvents<T>(eventType, processedEvents as T[]);
  const throttledReportAction = getThrottledReportLogEventsInBatch(
    getTelemetryClientConfig().eventReportIntervalInMilliseconds
  );

  throttledReportAction();
};

type ReportLogEvent = (event: LogEvent) => void;
export const reportLogEvent: ReportLogEvent = (event) => {
  enqueueAndThrottleReportLogEvents(event.type, [event], true);
};

type ReportLogEventNow = (event: LogEvent) => Promise<void>;

export const reportLogEventNow: ReportLogEventNow = async (event) => {
  const logEvents: LogEvents = {
    errorEvents: event.type === 'Error' ? [event] : [],
    infoEvents: event.type === 'Info' ? [event] : [],
    performanceEvents: event.type === 'Performance' ? [event] : [],
  };
  if (!eventsAllowedForReportNow.includes(event.name)) {
    logErrorToConsole('Event not allowed for report now');

    return;
  }

  const reportLogEventInput = await getReportLogEventsInput(logEvents);
  await reportLogEventsNow(reportLogEventInput);
};
