/**
 * The root paths for the web app.
 * if you're adding a new root path that is not pointing to resources(e.g. thread/group/article),
 * please add it to the ignorePaths array in resourcePath.ts
 */
export const urlRootPaths = [
  'achievements',
  'articles',
  'authredirect',
  'configure',
  'curated-thread-list',
  'discover-groups',
  'events',
  'feed',
  'groups',
  'group-optimizations',
  'campaigns',
  'logout',
  'inbox',
  'campaigns-dashboard',
  'campaigns-hub',
  'questions',
  'search',
  'storylines',
  'teams-meeting',
  'threads',
  'topics',
  'topics-network-question',
  'network-themes',
  'usage-policy',
  'users',
  'user-settings',
  'org',
  'network',
  'admin',
  'leader-dashboard',
  'leadership-corner',
  'leaders',
  'ama',
  'insights',
  'media-post',
  'dashboard',
] as const;
export type UrlRootPath = (typeof urlRootPaths)[number];

export const networkSchemaRootPaths: UrlRootPath[] = ['org', 'network'];
