import { HostingConfig } from '@ms/yammer-telemetry-support';

const initialHostingConfig: Record<string, string> & HostingConfig = {
  cdnBaseUrl: '',
  hostingEnvironment: 'dev',
  hostname: '',
  forest: '',
  scriptRevision: '',
  scriptBaseUrl: '',
  server: '',
  serverPageVer: '',
  userBrowserIdentity: '',
};

const metaTagToHostingConfigMap: Record<string, string> = {
  cdnBaseUrl: 'cdnBaseUrl',
  clientId: 'userBrowserIdentity',
  dring: 'hostingEnvironment',
  fost: 'forest',
  scriptBaseUrl: 'scriptBaseUrl',
  scriptVer: 'scriptRevision',
  server: 'server',
  serverPageVer: 'serverPageVer',
};

export type ReadHostingConfigFromMetatags = () => HostingConfig;

export const readHostingConfigFromMetatags: ReadHostingConfigFromMetatags = () =>
  Object.keys(metaTagToHostingConfigMap).reduce<HostingConfig>((accumulatedValues, tagName) => {
    const head = document.getElementsByTagName('head')[0];
    const metaTag: HTMLMetaElement | null = head.querySelector(`[name='${tagName}']`);
    const hostingConfigKey = metaTagToHostingConfigMap[tagName];

    return {
      ...accumulatedValues,
      [hostingConfigKey]: metaTag ? metaTag.content : initialHostingConfig[hostingConfigKey],
    };
  }, initialHostingConfig);
