import { RequestInitiator } from '@ms/yammer-telemetry-support';

import { endAndReportPerformanceMeasure, startPerformanceMeasure } from '../telemetry';

import { acquireToken, getAuthenticator } from './authenticator';

interface AcquireTokenOptions {
  readonly scopes: string[];
  readonly timeout?: number;
  readonly silent?: boolean;
  readonly initiator?: RequestInitiator;
}

export class AcquireTokenTimedoutError extends Error {
  public name = 'AcquireTokenTimedoutError';

  constructor(...args: Parameters<ErrorConstructor>) {
    super(...args);
    Object.setPrototypeOf(this, AcquireTokenTimedoutError.prototype);
  }
}

const rejectAfter = (ms: number, error: Error) =>
  new Promise<never>((_resolve, reject) =>
    setTimeout(() => {
      reject(error);
    }, ms)
  );

const acquireTokenWithTimeout: (scopes: string[], timeout: number, silent?: boolean) => Promise<string> = (
  scopes,
  timeout,
  silent
) => {
  const acquireTokenPromise = acquireToken({ scopes, silent });
  const timeoutPromise = rejectAfter(timeout, new AcquireTokenTimedoutError());

  return Promise.race([acquireTokenPromise, timeoutPromise]);
};

export type AcquireTokenWithTelemetry = (options: AcquireTokenOptions) => Promise<string>;
export const acquireTokenWithTelemetry: AcquireTokenWithTelemetry = async ({ scopes, timeout, silent, initiator }) => {
  let endedWithError = false;
  const { type: authenticatorType } = getAuthenticator();
  const measure = startPerformanceMeasure('acquire_token_for_resource');
  try {
    if (timeout) {
      return await acquireTokenWithTimeout(scopes, timeout, silent);
    }

    return await acquireToken({ scopes, silent });
  } catch (error) {
    endedWithError = true;
    throw error;
  } finally {
    endAndReportPerformanceMeasure({
      ...measure,
      eventProperties: { ...measure.eventProperties, endedWithError, initiator, authenticatorType },
    });
  }
};

export type AcquireTokenWithRetry = (options: AcquireTokenOptions) => Promise<string>;
export const acquireTokenWithRetry: AcquireTokenWithRetry = async (options) => {
  try {
    return await acquireTokenWithTelemetry(options);
  } catch {
    return acquireTokenWithTelemetry(options);
  }
};
